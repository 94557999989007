"use client";

import React from "react";
import PropTypes from "prop-types";

const SET_SPLASH_SCREEN_VISIBLE = "SET_SPLASH_SCREEN_VISIBLE";
const SET_LOGOUT_TOAST = "SET_LOGOUT_TOAST";
const DEPRECATED_LOCAL_STORAGE_KEYS = ["TAB_COUNT", "TAB_COUNT_V2"];

const missingAppConfigProvider =
  "You forgot to wrap your app in <AppConfigProvider>";
const AppConfigContext = React.createContext({
  get appConfig() {
    throw new Error(missingAppConfigProvider);
  },
  get changeAppConfig() {
    throw new Error(missingAppConfigProvider);
  },
});

AppConfigContext.displayName = "AppConfigContext";

const initialState = {
  adProductSplashScreen: {
    seen: false,
    visible: false,
  },
  toasts: {
    logout: false,
  },
};

function reducer(state, action) {
  switch (action.type) {
    case SET_SPLASH_SCREEN_VISIBLE:
      return {
        ...state,
        adProductSplashScreen: {
          seen: !action.payload,
          visible: action.payload,
        },
      };

    case SET_LOGOUT_TOAST:
      return {
        ...state,
        toasts: {
          ...state.toasts,
          logout: action.payload,
        },
      };

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export default function Provider({ children }) {
  const [appConfig, changeAppConfig] = React.useReducer(reducer, initialState);

  React.useEffect(() => {
    // Delete deprecated local storage keys
    DEPRECATED_LOCAL_STORAGE_KEYS.forEach((key) => {
      if (localStorage.getItem(key)) {
        localStorage.removeItem(key);
      }
    });
  }, []);

  const setSplashScreenVisibility = React.useCallback((isVisible) => {
    changeAppConfig({
      type: SET_SPLASH_SCREEN_VISIBLE,
      payload: isVisible,
    });
  }, []);

  const setLogoutToastVisibility = React.useCallback((isVisible) => {
    changeAppConfig({
      type: SET_LOGOUT_TOAST,
      payload: isVisible,
    });
  }, []);

  return (
    <AppConfigContext.Provider
      value={{ appConfig, setSplashScreenVisibility, setLogoutToastVisibility }}
    >
      {children}
    </AppConfigContext.Provider>
  );
}

function useAppConfig() {
  const {
    appConfig,
    setSplashScreenVisibility,
    setLogoutToastVisibility,
  } = React.useContext(AppConfigContext);

  return { appConfig, setSplashScreenVisibility, setLogoutToastVisibility };
}

Provider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export { useAppConfig };
