import React from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { SECTIONS } from "@gonoodle/gn-universe-analytics-schema/src/constants";
import { useSearchParams } from "next/navigation";
import { concatenateQueryParams } from "@gonoodle/gn-universe-utils";

import api from "../api";
import { QUERY_KEYS, ROUTE_PREFIX, OAUTH_INITIATORS } from "../constants";
import useRouter from "./useRouter";
import { useLogEvent } from "../contexts/Analytics";

const client = api();

export default function useLogin({
  redirectRoute = `/${ROUTE_PREFIX.PROFILES}`,
  onSuccess = () => {},
} = {}) {
  const queryClient = useQueryClient();
  const router = useRouter();
  const searchParams = useSearchParams();
  const [errorMessage, setErrorMessage] = React.useState();

  const { logEvent: loginSucceededEvent } = useLogEvent({
    event: "Login Succeeded",
    options: {
      includeReferrer: false,
      includeSourcePage: false,
      includeSourcePageType: false,
    },
  });

  const { logEvent: loginFailedEvent } = useLogEvent({
    event: "Login Failed",
    properties: {
      sourceElement: SECTIONS.LOGIN_BUTTON,
    },
    options: {
      includeReferrer: false,
      includeSourcePage: false,
      includeSourcePageType: false,
    },
  });

  const { mutate: login, error, isPending: isLoading } = useMutation({
    mutationFn: (userCredentials) =>
      client.login(userCredentials.email, userCredentials.password),
    onSuccess: async (...params) => {
      const oauthInitiator = searchParams.get("oauth_initiator");
      const clonedSearchParams = new URLSearchParams(searchParams.toString());

      if (typeof redirectRoute !== "string" && redirectRoute.query) {
        Object.keys(redirectRoute.query).forEach((key) => {
          clonedSearchParams.set(key, redirectRoute.query[key]);
        });
      }

      const pathname =
        typeof redirectRoute === "string"
          ? redirectRoute
          : redirectRoute.pathname;

      await onSuccess(...params);

      loginSucceededEvent({
        sourceElement:
          oauthInitiator === OAUTH_INITIATORS.CLEVER_LOGIN
            ? SECTIONS.LOGIN_WITH_CLEVER_BUTTON
            : oauthInitiator === OAUTH_INITIATORS.CLEVER_SIGNUP
            ? SECTIONS.REGISTER_WITH_CLEVER_BUTTON
            : oauthInitiator === OAUTH_INITIATORS.GOOGLE_LOGIN
            ? SECTIONS.LOGIN_WITH_GOOGLE_BUTTON
            : oauthInitiator === OAUTH_INITIATORS.GOOGLE_SIGNUP
            ? SECTIONS.REGISTER_WITH_GOOGLE_BUTTON
            : SECTIONS.LOGIN_BUTTON,
      });

      router.replace(
        concatenateQueryParams(
          pathname,
          Object.fromEntries(clonedSearchParams.entries()),
        ),
        undefined,
        typeof redirectRoute === "string" ? {} : redirectRoute.referrer,
      );

      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.USER],
      });

      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.USER_ACCESSES],
      });
    },
  });

  React.useEffect(() => {
    const getErrorMessage = async () => {
      try {
        const { error: message } = await error.response.json();
        setErrorMessage(message);
        loginFailedEvent({ failureReason: message });
      } catch {
        /* empty */
      }
    };
    if (error) {
      getErrorMessage();
    } else {
      setErrorMessage(undefined);
    }
  }, [error, loginFailedEvent]);

  return { login, error: errorMessage, isLoading };
}
