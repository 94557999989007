import React from "react";
import PropTypes from "prop-types";

import Button, { BUTTON_VARIANTS, BUTTON_SIZES } from "../Button";
import BackgroundVideo from "../BackgroundVideo";

export default function Spotlight({
  title,
  description,
  buttonLabel,
  onClick,
  source,
  fallbackImageSource,
  reverse = false,
  textTheme = "dark",
  bgColor = "000",
  className,
}) {
  const mode = textTheme === "light" ? "text-white" : "text-black";

  return (
    <div
      className={`flex flex-col lg:flex-row lg:h-screen-xs w-full rounded-lg overflow-hidden shadow-darkSM hover:shadow-darkLG ${className}`}
      style={{ backgroundColor: `#${bgColor}` }}
    >
      <div
        className={`${
          reverse ? "order-first lg:order-last" : "order-first"
        } h-full w-full lg:w-3/5`}
      >
        <BackgroundVideo
          source={source}
          fallbackImage={{ sources: fallbackImageSource, alt: title }}
          className="object-cover w-full h-3xl sm:h-3xl lg:h-full"
        />
      </div>

      <div
        className={`${
          reverse ? "order-last lg:order-first" : "order-last"
        } flex flex-col justify-center items-start w-full lg:w-2/5 p-lg space-y-md`}
      >
        <p className={`gn-headline-lg ${mode}`}>{title}</p>
        <p className={`gn-text line-clamp-4 ${mode}`}>{description}</p>
        <Button
          variant={
            textTheme === "light"
              ? BUTTON_VARIANTS.lightOutline
              : BUTTON_VARIANTS.darkOutline
          }
          size={BUTTON_SIZES.md}
          onPress={onClick}
        >
          {buttonLabel}
        </Button>
      </div>
    </div>
  );
}

Spotlight.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  source: PropTypes.string,
  fallbackImageSource: PropTypes.shape({
    "regular@1x": PropTypes.string,
    "regular@2x": PropTypes.string,
    "regular@3x": PropTypes.string,
  }),
  textTheme: PropTypes.string,
  bgColor: PropTypes.string,
  reverse: PropTypes.bool,
  className: PropTypes.string,
};
