import { PROFILE_TYPES } from "../constants";

export default ({ get, post, put, del, checkStatus, toJSON }) => ({
  createUser: () =>
    post("universe/v1/users", { includeClientToken: true })
      .then((response) => checkStatus({ response, method: "POST" }))
      .then(toJSON),
  getUser: () =>
    get("universe/v1/users")
      .then((response) => checkStatus({ response, method: "GET" }))
      .then(toJSON),
  updateUser: (newUserProperties) =>
    put("universe/v1/users", {
      body: newUserProperties,
    })
      .then((response) => checkStatus({ response, method: "PUT" }))
      .then(toJSON),
  updateUserFlags: ({ importCleverProfiles, ...rest }) =>
    put("universe/v1/users/update_flags", {
      body: {
        cleverClassroomSync: importCleverProfiles,
        ...rest,
      },
    })
      .then((response) => checkStatus({ response }))
      .then(toJSON),
  adViewed: (adType) => {
    post("universe/v1/users/view_ad", {
      body: {
        adType,
      },
    }).then((response) => checkStatus({ response, method: "POST" }));
  },
  getGrades: () =>
    get("universe/v1/users/grades")
      .then((response) => checkStatus({ response, method: "GET" }))
      .then(toJSON),
  getUserAccesses: () =>
    get("universe/v1/piano/list_user_access")
      .then((response) => checkStatus({ response, method: "GET" }))
      .then(toJSON),
  validateEmail: (email) =>
    post("universe/v1/users/validate_email", {
      body: {
        email,
      },
    })
      .then((response) => checkStatus({ response, method: "POST" }))
      .then(toJSON),
  register: (user) =>
    put("universe/v1/users/register", {
      body: {
        email: user.email,
        password: user.password,
        recaptchaToken: user.recaptchaToken,
        receiveEmails: user.allowMarketingEmails,
        cleverClassroomSync: user.importCleverProfiles,
        classroomsAttributes: user.profileType
          ? [
              {
                classroomType: user.profileType,
                schoolId:
                  user.profileType === PROFILE_TYPES.CLASSROOM
                    ? user.school.id
                    : undefined,
                size:
                  user.profileType === PROFILE_TYPES.CLASSROOM
                    ? user.numberOfKids
                    : undefined,
                name:
                  user.profileType === PROFILE_TYPES.CLASSROOM
                    ? user.className
                    : user.userName,
                gradeId: user.grade.id,
                classroomChampsAttributes: user.selectedChamp
                  ? [
                      {
                        champId: user.selectedChamp?.id,
                        points: user.loggedOutChampPoints,
                      },
                    ]
                  : [],
              },
            ]
          : undefined,
      },
    })
      .then((response) => checkStatus({ response, method: "PUT" }))
      .then(toJSON),
  login: (email, password) =>
    post("universe/v1/authentication/login", {
      body: {
        email,
        password,
      },
    })
      .then((response) => checkStatus({ response, method: "POST" }))
      .then(toJSON),
  createProfile: ({
    userName,
    profileType,
    grade,
    numberOfKids,
    school,
    className,
    champId,
  }) =>
    post("universe/v1/users/create_profile", {
      body: {
        name: userName || className,
        classroomType: profileType,
        gradeId: grade.id,
        schoolId: school?.id,
        size: numberOfKids,
        classroomChampsAttributes: [{ champId }],
      },
    })
      .then((response) => checkStatus({ response, method: "POST" }))
      .then(toJSON),
  updateProfile: ({
    userName,
    grade,
    numberOfKids,
    school,
    className,
    profileId,
  }) =>
    put("universe/v1/users/update_profile", {
      body: {
        id: profileId,
        classroomsAttributes: Object.fromEntries(
          Object.entries({
            name: userName || className,
            gradeId: grade?.id,
            schoolId: school?.id,
            size: numberOfKids,
          }).filter(([, value]) => value !== undefined),
        ),
      },
    })
      .then((response) => checkStatus({ response, method: "PUT" }))
      .then(toJSON),
  deleteProfile: (id) =>
    del("universe/v1/users/delete_profile", {
      body: {
        id,
      },
    })
      .then((response) => checkStatus({ response, method: "DEL" }))
      .then(toJSON),
  forgotPassword: (email) =>
    post("universe/v1/passwords/forgot", {
      body: {
        email,
      },
    })
      .then((response) => checkStatus({ response, method: "POST" }))
      .then(toJSON),
  resetPassword: (password, resetToken) =>
    post("universe/v1/passwords/reset_user", {
      body: {
        password,
        resetToken,
      },
    })
      .then((response) => checkStatus({ response, method: "POST" }))
      .then(toJSON),
  logout: () =>
    del("universe/v1/authentication/logout")
      .then((response) => checkStatus({ response, method: "DEL" }))
      .then(toJSON),
  redeemContract: (contractId) =>
    post("universe/v1/piano/redeem_contract", {
      body: {
        contractId,
      },
    })
      .then((response) => checkStatus({ response, method: "POST" }))
      .then(toJSON),
});
