import React from "react";

export default function useCounter(initialValue) {
  const [count, setCount] = React.useState(initialValue ?? 0);

  const increment = React.useCallback(() => {
    setCount((x) => x + 1);
  }, []);

  const decrement = React.useCallback(() => {
    setCount((x) => x - 1);
  }, []);

  const reset = React.useCallback(() => {
    setCount(initialValue ?? 0);
  }, [initialValue]);

  return {
    count,
    increment,
    decrement,
    reset,
    setCount,
  };
}
