import { omitBy, isUndefined } from "lodash";
import {
  PAGES,
  PAGES_TYPES,
} from "@gonoodle/gn-universe-analytics-schema/src/constants";

function replaceHyphensWithSpaces(str) {
  return typeof str === "string" ? str.replace(/-/g, " ") : undefined;
}

export default function getPageAndPageType(pathname, params = {}) {
  const { identity } = params;
  const pageSlug = identity ? replaceHyphensWithSpaces(identity[1]) : undefined;

  let sourcePage = undefined;
  let sourcePageType = undefined;

  switch (true) {
    case /^\/$|^\/\?.*$/.test(pathname):
      sourcePage = PAGES.HOME;
      sourcePageType = PAGES.HOME;
      break;
    case /^\/videos\/[^/]+(?:\/[^/]+)*$/.test(pathname):
      sourcePage = `${pageSlug} detail page`;
      sourcePageType = PAGES.VIDEO_DETAIL;
      break;
    case /^\/activities\/[^/]+(?:\/[^/]+)*$/.test(pathname):
      sourcePage = `${pageSlug} detail page`;
      sourcePageType = PAGES.PRINTABLE_DETAIL;
      break;
    case /^\/tags\/[^/]+(?:\/[^/]+)*$/.test(pathname):
      sourcePage = params.isPrimaryContentTag
        ? `${pageSlug} listing page`
        : `${pageSlug} landing page`;
      sourcePageType = params.isPrimaryContentTag
        ? PAGES_TYPES.LISTING
        : PAGES_TYPES.LANDING;
      break;
    case /^\/games\/[^/]+(?:\/[^/]+)*$/.test(pathname):
      sourcePage = `${pageSlug} detail page`;
      sourcePageType = PAGES.GAME_DETAIL;
      break;
    case /^\/account-settings(\?.*)?$/.test(pathname):
      sourcePage = PAGES.ACCOUNT_SETTINGS;
      sourcePageType = PAGES.ACCOUNT_SETTINGS;
      break;
    case /^\/profiles(\?.*)?$/.test(pathname):
      sourcePage = PAGES.PROFILE;
      sourcePageType = PAGES.PROFILE;
      break;
    case /^\/login(\?.*)?$/.test(pathname):
      sourcePage = PAGES.LOGIN;
      sourcePageType = PAGES.LOGIN;
      break;
    case /^\/registration(\?.*)?$/.test(pathname):
      sourcePage = PAGES.REGISTRATION;
      sourcePageType = PAGES.REGISTRATION;
      break;
    case /^\/forgot-password(\?.*)?$/.test(pathname):
      sourcePage = PAGES.RESET_PASSWORD;
      sourcePageType = PAGES.RESET_PASSWORD;
      break;
    case /^\/newsletter-signup(\?.*)?$/.test(pathname):
      sourcePage = PAGES.NEWSLETTER_SIGNUP;
      sourcePageType = PAGES.NEWSLETTER_SIGNUP;
      break;
    case /^\/search(\?.*)?$/.test(pathname):
      sourcePage = PAGES.SEARCH;
      sourcePageType = PAGES.SEARCH;
      break;
    case /^\/curriculum\/[^/]+$/.test(pathname):
      sourcePage = PAGES.CURRICULUM_COMPETENCIES;
      sourcePageType = PAGES.CURRICULUM_COMPETENCIES;
      break;
    case /^\/curriculum\/[^/]+\/[^/]+$/.test(pathname):
      sourcePage = PAGES.CURRICULUM_ELEMENT;
      sourcePageType = PAGES.CURRICULUM_ELEMENT;
      break;
    case /^\/trophies(\?.*)?$/.test(pathname):
      sourcePage = PAGES.TROPHIES;
      sourcePageType = PAGES.TROPHIES;
      break;
    default:
      break;
  }

  return omitBy({ sourcePage, sourcePageType }, isUndefined);
}
